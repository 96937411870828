import { Box, Chip } from '@mui/material';

const SelectableChips = ({ selected, setSelected, options }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {options.map((option, index) => (
                <Chip
                    key={index}
                    label={option.label}
                    color="primary"
                    onClick={() => setSelected(option.tag)}
                    sx={{
                        fontWeight: selected === option.tag ? 'bold' : 'normal',
                        cursor: 'pointer',
                        textTransform: 'capitalize',
                    }}
                    variant='filled'
                />
            ))}
        </Box>
    );
};

export default SelectableChips;
